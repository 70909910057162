.create-task-container {
  background: #ffffff;
  width: 55%;
  height: 90vh;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15% 85%;

  .task-creation-steps-container {
    padding: 0 10%;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .step-content {
      height: 70%;
    }

    .step-actions {
      flex: 0.3;
    }
  }
}

.task-details-form {
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    flex: 1;
  }
}

.collaborators-selection {
  overflow-y: scroll;
  width: 100%;
  margin: 0 auto;
  height: 60%;
}

.attachment-form {
  height: 80%;
  display: flex;
  flex-direction: column;

  .attachments-list-area {
    flex: 2;
    display: flex;
    flex-direction: column;

    .attachments-list-item {
      width: 100%;
      height: 40px;
      padding: 0.5em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px dashed #ececec;
    }
  }
}

.task-meta-form {
  width: 80%;
  margin: 0 auto;
  .mpt-form-item-container {
    margin: 0 0 2em 0;
  }
}

.horizontal-card-item-container {
  background: #ececec;
  padding: 1em;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: scroll;
  gap: 1em;

  .horizontal-card-item {
    width: 200px;
    height: 100%;
  }
}

.mpt-form-item-container {
  //background: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60px;

  span {
  }
}
