.chat-bar{
  width: 25%;
  position: absolute;
  height: 90vh;
  bottom: 0;
  right: 0;
  margin-right: 0.8%;
  z-index: 10;
  background: white;
  border-radius: 12px;
  border: solid 2px #f0f4f9;
}
.chat-bar-search-and-filter{
  width: 100%;
  padding: 0 1.5em 1em;
}

.chat-creation-actions{
  width: 100%;
  padding: 1em 1.5em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.chat-list-item{
  :hover{
    background: #f0f4f9;
    cursor: pointer;
  }
}

.chat-bar-card{
  cursor: pointer;
}
