.active-alarms-container{
  width: 100%;
  padding: 0 1em;
  display: grid;
  grid-template-columns: 2% 98%;
  margin-bottom: 10px;

  .priority-indicator{
    width: 100%;
    height: 100%;
  }

  .alarm-description-container{
    padding: 0;
    .alarm-description-container-item{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }
  }

}
