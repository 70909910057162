.task-dashboard-container {
  width: 85%;
  height: 90vh;
  margin: 0 auto;
  //padding: 20px 0 0 0;

  //display: grid;
  //grid-template-columns: 25% 75%;
  //grid-gap: 10px;
  //grid-template-rows: 8vh 92vh;
}
.task-calendar-section {
  width: 100%;
  height: 80%;
  padding: 0;
  //background: #ffffff;

  display: grid;
  grid-template-columns: 65% 35%;
  grid-template-rows: 80%;
  grid-gap: 1em;

  .calendar {
    width: 100%;
    height: 74vh;
    padding: 0;
    overflow-y: hidden;
  }

  .calendar-list-section {
    overflow-y: auto;
    // background: red;
    width: 100%;
    height: 90%;
    // overflow-y: scroll;
    padding: 0 2em;
    border-left: 1px solid #ececec;
  }
}

.task-list-area {
  width: 100%;
  height: 100%;
  //padding:0 1em;
  padding: 0;
  margin: 0 auto;
  ///*background: #000c37;*/
}

.no-padding {
  padding: 0 !important;
}

.task-search-filter-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  background: transparent;
  //height: 120px;

  .text-search {
  }
}

.task-list {
  width: 100%;
  min-height: 90vh;
  height: 100%;
}

.calendar-events {
  margin: 0;
  padding: 0;
  list-style: none;

  .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
