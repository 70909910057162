.task-message-item{
  width: 60%;
  margin-bottom: 10px;
  margin-left: 10%;
  border-radius: 10px;
  //background: #00bfb6;
  background: #fff;
  padding: .6em;
  //font-family: arial;
  position: relative;
}

.message-from-you{
  margin-left: 30%;
}

.message-not-from-you{

}

.sb13:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid #fff;
  border-right: 15px solid transparent;
  border-top: 15px solid #fff;
  border-bottom: 15px solid transparent;
  right: -16px;
  top: 0px;
}

.sb14:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid #fff;
  border-top: 15px solid #fff;
  border-bottom: 15px solid transparent;
  left: -16px;
  top: 0px;
}
