.task-view-container {
  margin: 0.2vh 0 0 0;
  width: 100%;
  height: 75vh;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 60% 40%;
  grid-gap: 1em;
  padding: 1vh;

  .task-view-details-container {
    padding: 5px;
    background: white;
    //border-radius: 25px;
  }

  .task-view-details-tab-pane {
    padding: 0;
  }

  .task-messages-container {
    height: 85vh; // Definite height
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 0.1fr;
    //padding: 1rem;
    margin-right: 2rem;
    // background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
    background: #efe7dd url("../../../../components/images/chat-background.jpg")
      repeat;

    .msgs {
      width: 100%;
      height: 100%;
      padding: 1em 0;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .text-area-container {
      background: #ffffff;
      padding: 12px;
      .text-area-container-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //padding: 12px;
      }
    }
  }
}

.timeline-container {
  padding: 2rem;
  width: 100%;
  height: 80%;
  overflow-y: scroll;
}

.collaborators-selection-container {
  height: 100%;
  overflow-y: scroll;
}

.attachments-container {
  padding: 0;
  height: 100%;
  overflow: hidden;

  .attachments-list-container {
    width: 100%;
    height: 40%;
    overflow-y: scroll;
  }
}

.attached-alarms-container {
  height: 100%;
  width: 100%;
  background: red;
}
